import 'ui-core/plugins/enquireJs/js/enquire';

import 'ui-core/plugins/controller/js/enquireIt';

/*eslint-disable*/
enquire.register('screen and (max-width:' + (UX.grid.articleLg - 1) + 'px)', {
    match: function () {
        $(document).trigger('screen-article-lg-on'); // screen is article sized
        $(document).trigger('smartResize'); // resize have been made
    },
    unmatch: function () {
        $(document).trigger('screen-article-lg-off'); // screen is not article sized
        $(document).trigger('smartResize'); // resize have been made
    },
});
