/*! jQuery UI - v1.12.1 - 2020-06-11
 * http://jqueryui.com
 * Includes: widget.js, position.js, keycode.js, unique-id.js, widgets/autocomplete.js, widgets/menu.js, widgets/tooltip.js
 * Copyright jQuery Foundation and other contributors; Licensed MIT */
/*eslint-disable*/
(function (t) {
    'function' == typeof define && define.amd ? define(['jquery'], t) : t(jQuery);
})(function (t) {
    (t.ui = t.ui || {}), (t.ui.version = '1.12.1');
    var e = 0,
        i = Array.prototype.slice;
    (t.cleanData = (function (e) {
        return function (i) {
            var s, n, o;
            for (o = 0; null != (n = i[o]); o++)
                try {
                    (s = t._data(n, 'events')), s && s.remove && t(n).triggerHandler('remove');
                } catch (a) {}
            e(i);
        };
    })(t.cleanData)),
        (t.widget = function (e, i, s) {
            var n,
                o,
                a,
                l = {},
                r = e.split('.')[0];
            e = e.split('.')[1];
            var u = r + '-' + e;
            return (
                s || ((s = i), (i = t.Widget)),
                t.isArray(s) && (s = t.extend.apply(null, [{}].concat(s))),
                (t.expr[':'][u.toLowerCase()] = function (e) {
                    return !!t.data(e, u);
                }),
                (t[r] = t[r] || {}),
                (n = t[r][e]),
                (o = t[r][e] =
                    function (t, e) {
                        return this._createWidget
                            ? (arguments.length && this._createWidget(t, e), void 0)
                            : new o(t, e);
                    }),
                t.extend(o, n, {version: s.version, _proto: t.extend({}, s), _childConstructors: []}),
                (a = new i()),
                (a.options = t.widget.extend({}, a.options)),
                t.each(s, function (e, s) {
                    return t.isFunction(s)
                        ? ((l[e] = (function () {
                              function t() {
                                  return i.prototype[e].apply(this, arguments);
                              }
                              function n(t) {
                                  return i.prototype[e].apply(this, t);
                              }
                              return function () {
                                  var e,
                                      i = this._super,
                                      o = this._superApply;
                                  return (
                                      (this._super = t),
                                      (this._superApply = n),
                                      (e = s.apply(this, arguments)),
                                      (this._super = i),
                                      (this._superApply = o),
                                      e
                                  );
                              };
                          })()),
                          void 0)
                        : ((l[e] = s), void 0);
                }),
                (o.prototype = t.widget.extend(a, {widgetEventPrefix: n ? a.widgetEventPrefix || e : e}, l, {
                    constructor: o,
                    namespace: r,
                    widgetName: e,
                    widgetFullName: u,
                })),
                n
                    ? (t.each(n._childConstructors, function (e, i) {
                          var s = i.prototype;
                          t.widget(s.namespace + '.' + s.widgetName, o, i._proto);
                      }),
                      delete n._childConstructors)
                    : i._childConstructors.push(o),
                t.widget.bridge(e, o),
                o
            );
        }),
        (t.widget.extend = function (e) {
            for (var s, n, o = i.call(arguments, 1), a = 0, l = o.length; l > a; a++)
                for (s in o[a])
                    (n = o[a][s]),
                        o[a].hasOwnProperty(s) &&
                            void 0 !== n &&
                            (e[s] = t.isPlainObject(n)
                                ? t.isPlainObject(e[s])
                                    ? t.widget.extend({}, e[s], n)
                                    : t.widget.extend({}, n)
                                : n);
            return e;
        }),
        (t.widget.bridge = function (e, s) {
            var n = s.prototype.widgetFullName || e;
            t.fn[e] = function (o) {
                var a = 'string' == typeof o,
                    l = i.call(arguments, 1),
                    r = this;
                return (
                    a
                        ? this.length || 'instance' !== o
                            ? this.each(function () {
                                  var i,
                                      s = t.data(this, n);
                                  return 'instance' === o
                                      ? ((r = s), !1)
                                      : s
                                      ? t.isFunction(s[o]) && '_' !== o.charAt(0)
                                          ? ((i = s[o].apply(s, l)),
                                            i !== s && void 0 !== i
                                                ? ((r = i && i.jquery ? r.pushStack(i.get()) : i), !1)
                                                : void 0)
                                          : t.error("no such method '" + o + "' for " + e + ' widget instance')
                                      : t.error(
                                            'cannot call methods on ' +
                                                e +
                                                ' prior to initialization; ' +
                                                "attempted to call method '" +
                                                o +
                                                "'"
                                        );
                              })
                            : (r = void 0)
                        : (l.length && (o = t.widget.extend.apply(null, [o].concat(l))),
                          this.each(function () {
                              var e = t.data(this, n);
                              e ? (e.option(o || {}), e._init && e._init()) : t.data(this, n, new s(o, this));
                          })),
                    r
                );
            };
        }),
        (t.Widget = function () {}),
        (t.Widget._childConstructors = []),
        (t.Widget.prototype = {
            widgetName: 'widget',
            widgetEventPrefix: '',
            defaultElement: '<div>',
            options: {classes: {}, disabled: !1, create: null},
            _createWidget: function (i, s) {
                (s = t(s || this.defaultElement || this)[0]),
                    (this.element = t(s)),
                    (this.uuid = e++),
                    (this.eventNamespace = '.' + this.widgetName + this.uuid),
                    (this.bindings = t()),
                    (this.hoverable = t()),
                    (this.focusable = t()),
                    (this.classesElementLookup = {}),
                    s !== this &&
                        (t.data(s, this.widgetFullName, this),
                        this._on(!0, this.element, {
                            remove: function (t) {
                                t.target === s && this.destroy();
                            },
                        }),
                        (this.document = t(s.style ? s.ownerDocument : s.document || s)),
                        (this.window = t(this.document[0].defaultView || this.document[0].parentWindow))),
                    (this.options = t.widget.extend({}, this.options, this._getCreateOptions(), i)),
                    this._create(),
                    this.options.disabled && this._setOptionDisabled(this.options.disabled),
                    this._trigger('create', null, this._getCreateEventData()),
                    this._init();
            },
            _getCreateOptions: function () {
                return {};
            },
            _getCreateEventData: t.noop,
            _create: t.noop,
            _init: t.noop,
            destroy: function () {
                var e = this;
                this._destroy(),
                    t.each(this.classesElementLookup, function (t, i) {
                        e._removeClass(i, t);
                    }),
                    this.element.off(this.eventNamespace).removeData(this.widgetFullName),
                    this.widget().off(this.eventNamespace).removeAttr('aria-disabled'),
                    this.bindings.off(this.eventNamespace);
            },
            _destroy: t.noop,
            widget: function () {
                return this.element;
            },
            option: function (e, i) {
                var s,
                    n,
                    o,
                    a = e;
                if (0 === arguments.length) return t.widget.extend({}, this.options);
                if ('string' == typeof e)
                    if (((a = {}), (s = e.split('.')), (e = s.shift()), s.length)) {
                        for (n = a[e] = t.widget.extend({}, this.options[e]), o = 0; s.length - 1 > o; o++)
                            (n[s[o]] = n[s[o]] || {}), (n = n[s[o]]);
                        if (((e = s.pop()), 1 === arguments.length)) return void 0 === n[e] ? null : n[e];
                        n[e] = i;
                    } else {
                        if (1 === arguments.length) return void 0 === this.options[e] ? null : this.options[e];
                        a[e] = i;
                    }
                return this._setOptions(a), this;
            },
            _setOptions: function (t) {
                var e;
                for (e in t) this._setOption(e, t[e]);
                return this;
            },
            _setOption: function (t, e) {
                return (
                    'classes' === t && this._setOptionClasses(e),
                    (this.options[t] = e),
                    'disabled' === t && this._setOptionDisabled(e),
                    this
                );
            },
            _setOptionClasses: function (e) {
                var i, s, n;
                for (i in e)
                    (n = this.classesElementLookup[i]),
                        e[i] !== this.options.classes[i] &&
                            n &&
                            n.length &&
                            ((s = t(n.get())),
                            this._removeClass(n, i),
                            s.addClass(this._classes({element: s, keys: i, classes: e, add: !0})));
            },
            _setOptionDisabled: function (t) {
                this._toggleClass(this.widget(), this.widgetFullName + '-disabled', null, !!t),
                    t &&
                        (this._removeClass(this.hoverable, null, 'ui-state-hover'),
                        this._removeClass(this.focusable, null, 'ui-state-focus'));
            },
            enable: function () {
                return this._setOptions({disabled: !1});
            },
            disable: function () {
                return this._setOptions({disabled: !0});
            },
            _classes: function (e) {
                function i(i, o) {
                    var a, l;
                    for (l = 0; i.length > l; l++)
                        (a = n.classesElementLookup[i[l]] || t()),
                            (a = e.add ? t(t.unique(a.get().concat(e.element.get()))) : t(a.not(e.element).get())),
                            (n.classesElementLookup[i[l]] = a),
                            s.push(i[l]),
                            o && e.classes[i[l]] && s.push(e.classes[i[l]]);
                }
                var s = [],
                    n = this;
                return (
                    (e = t.extend({element: this.element, classes: this.options.classes || {}}, e)),
                    this._on(e.element, {remove: '_untrackClassesElement'}),
                    e.keys && i(e.keys.match(/\S+/g) || [], !0),
                    e.extra && i(e.extra.match(/\S+/g) || []),
                    s.join(' ')
                );
            },
            _untrackClassesElement: function (e) {
                var i = this;
                t.each(i.classesElementLookup, function (s, n) {
                    -1 !== t.inArray(e.target, n) && (i.classesElementLookup[s] = t(n.not(e.target).get()));
                });
            },
            _removeClass: function (t, e, i) {
                return this._toggleClass(t, e, i, !1);
            },
            _addClass: function (t, e, i) {
                return this._toggleClass(t, e, i, !0);
            },
            _toggleClass: function (t, e, i, s) {
                s = 'boolean' == typeof s ? s : i;
                var n = 'string' == typeof t || null === t,
                    o = {extra: n ? e : i, keys: n ? t : e, element: n ? this.element : t, add: s};
                return o.element.toggleClass(this._classes(o), s), this;
            },
            _on: function (e, i, s) {
                var n,
                    o = this;
                'boolean' != typeof e && ((s = i), (i = e), (e = !1)),
                    s
                        ? ((i = n = t(i)), (this.bindings = this.bindings.add(i)))
                        : ((s = i), (i = this.element), (n = this.widget())),
                    t.each(s, function (s, a) {
                        function l() {
                            return e || (o.options.disabled !== !0 && !t(this).hasClass('ui-state-disabled'))
                                ? ('string' == typeof a ? o[a] : a).apply(o, arguments)
                                : void 0;
                        }
                        'string' != typeof a && (l.guid = a.guid = a.guid || l.guid || t.guid++);
                        var r = s.match(/^([\w:-]*)\s*(.*)$/),
                            u = r[1] + o.eventNamespace,
                            h = r[2];
                        h ? n.on(u, h, l) : i.on(u, l);
                    });
            },
            _off: function (e, i) {
                (i = (i || '').split(' ').join(this.eventNamespace + ' ') + this.eventNamespace),
                    e.off(i).off(i),
                    (this.bindings = t(this.bindings.not(e).get())),
                    (this.focusable = t(this.focusable.not(e).get())),
                    (this.hoverable = t(this.hoverable.not(e).get()));
            },
            _delay: function (t, e) {
                function i() {
                    return ('string' == typeof t ? s[t] : t).apply(s, arguments);
                }
                var s = this;
                return setTimeout(i, e || 0);
            },
            _hoverable: function (e) {
                (this.hoverable = this.hoverable.add(e)),
                    this._on(e, {
                        mouseenter: function (e) {
                            this._addClass(t(e.currentTarget), null, 'ui-state-hover');
                        },
                        mouseleave: function (e) {
                            this._removeClass(t(e.currentTarget), null, 'ui-state-hover');
                        },
                    });
            },
            _focusable: function (e) {
                (this.focusable = this.focusable.add(e)),
                    this._on(e, {
                        focusin: function (e) {
                            this._addClass(t(e.currentTarget), null, 'ui-state-focus');
                        },
                        focusout: function (e) {
                            this._removeClass(t(e.currentTarget), null, 'ui-state-focus');
                        },
                    });
            },
            _trigger: function (e, i, s) {
                var n,
                    o,
                    a = this.options[e];
                if (
                    ((s = s || {}),
                    (i = t.Event(i)),
                    (i.type = (e === this.widgetEventPrefix ? e : this.widgetEventPrefix + e).toLowerCase()),
                    (i.target = this.element[0]),
                    (o = i.originalEvent))
                )
                    for (n in o) n in i || (i[n] = o[n]);
                return (
                    this.element.trigger(i, s),
                    !((t.isFunction(a) && a.apply(this.element[0], [i].concat(s)) === !1) || i.isDefaultPrevented())
                );
            },
        }),
        t.each({show: 'fadeIn', hide: 'fadeOut'}, function (e, i) {
            t.Widget.prototype['_' + e] = function (s, n, o) {
                'string' == typeof n && (n = {effect: n});
                var a,
                    l = n ? (n === !0 || 'number' == typeof n ? i : n.effect || i) : e;
                (n = n || {}),
                    'number' == typeof n && (n = {duration: n}),
                    (a = !t.isEmptyObject(n)),
                    (n.complete = o),
                    n.delay && s.delay(n.delay),
                    a && t.effects && t.effects.effect[l]
                        ? s[e](n)
                        : l !== e && s[l]
                        ? s[l](n.duration, n.easing, o)
                        : s.queue(function (i) {
                              t(this)[e](), o && o.call(s[0]), i();
                          });
            };
        }),
        t.widget,
        (function () {
            function e(t, e, i) {
                return [
                    parseFloat(t[0]) * (c.test(t[0]) ? e / 100 : 1),
                    parseFloat(t[1]) * (c.test(t[1]) ? i / 100 : 1),
                ];
            }
            function i(e, i) {
                return parseInt(t.css(e, i), 10) || 0;
            }
            function s(e) {
                var i = e[0];
                return 9 === i.nodeType
                    ? {width: e.width(), height: e.height(), offset: {top: 0, left: 0}}
                    : t.isWindow(i)
                    ? {width: e.width(), height: e.height(), offset: {top: e.scrollTop(), left: e.scrollLeft()}}
                    : i.preventDefault
                    ? {width: 0, height: 0, offset: {top: i.pageY, left: i.pageX}}
                    : {width: e.outerWidth(), height: e.outerHeight(), offset: e.offset()};
            }
            var n,
                o = Math.max,
                a = Math.abs,
                l = /left|center|right/,
                r = /top|center|bottom/,
                u = /[\+\-]\d+(\.[\d]+)?%?/,
                h = /^\w+/,
                c = /%$/,
                d = t.fn.position;
            (t.position = {
                scrollbarWidth: function () {
                    if (void 0 !== n) return n;
                    var e,
                        i,
                        s = t(
                            "<div style='display:block;position:absolute;width:50px;height:50px;overflow:hidden;'><div style='height:100px;width:auto;'></div></div>"
                        ),
                        o = s.children()[0];
                    return (
                        t('body').append(s),
                        (e = o.offsetWidth),
                        s.css('overflow', 'scroll'),
                        (i = o.offsetWidth),
                        e === i && (i = s[0].clientWidth),
                        s.remove(),
                        (n = e - i)
                    );
                },
                getScrollInfo: function (e) {
                    var i = e.isWindow || e.isDocument ? '' : e.element.css('overflow-x'),
                        s = e.isWindow || e.isDocument ? '' : e.element.css('overflow-y'),
                        n = 'scroll' === i || ('auto' === i && e.width < e.element[0].scrollWidth),
                        o = 'scroll' === s || ('auto' === s && e.height < e.element[0].scrollHeight);
                    return {width: o ? t.position.scrollbarWidth() : 0, height: n ? t.position.scrollbarWidth() : 0};
                },
                getWithinInfo: function (e) {
                    var i = t(e || window),
                        s = t.isWindow(i[0]),
                        n = !!i[0] && 9 === i[0].nodeType,
                        o = !s && !n;
                    return {
                        element: i,
                        isWindow: s,
                        isDocument: n,
                        offset: o ? t(e).offset() : {left: 0, top: 0},
                        scrollLeft: i.scrollLeft(),
                        scrollTop: i.scrollTop(),
                        width: i.outerWidth(),
                        height: i.outerHeight(),
                    };
                },
            }),
                (t.fn.position = function (n) {
                    if (!n || !n.of) return d.apply(this, arguments);
                    n = t.extend({}, n);
                    var c,
                        p,
                        m,
                        f,
                        v,
                        g,
                        _ = t(n.of),
                        y = t.position.getWithinInfo(n.within),
                        b = t.position.getScrollInfo(y),
                        w = (n.collision || 'flip').split(' '),
                        C = {};
                    return (
                        (g = s(_)),
                        _[0].preventDefault && (n.at = 'left top'),
                        (p = g.width),
                        (m = g.height),
                        (f = g.offset),
                        (v = t.extend({}, f)),
                        t.each(['my', 'at'], function () {
                            var t,
                                e,
                                i = (n[this] || '').split(' ');
                            1 === i.length &&
                                (i = l.test(i[0])
                                    ? i.concat(['center'])
                                    : r.test(i[0])
                                    ? ['center'].concat(i)
                                    : ['center', 'center']),
                                (i[0] = l.test(i[0]) ? i[0] : 'center'),
                                (i[1] = r.test(i[1]) ? i[1] : 'center'),
                                (t = u.exec(i[0])),
                                (e = u.exec(i[1])),
                                (C[this] = [t ? t[0] : 0, e ? e[0] : 0]),
                                (n[this] = [h.exec(i[0])[0], h.exec(i[1])[0]]);
                        }),
                        1 === w.length && (w[1] = w[0]),
                        'right' === n.at[0] ? (v.left += p) : 'center' === n.at[0] && (v.left += p / 2),
                        'bottom' === n.at[1] ? (v.top += m) : 'center' === n.at[1] && (v.top += m / 2),
                        (c = e(C.at, p, m)),
                        (v.left += c[0]),
                        (v.top += c[1]),
                        this.each(function () {
                            var s,
                                l,
                                r = t(this),
                                u = r.outerWidth(),
                                h = r.outerHeight(),
                                d = i(this, 'marginLeft'),
                                g = i(this, 'marginTop'),
                                x = u + d + i(this, 'marginRight') + b.width,
                                E = h + g + i(this, 'marginBottom') + b.height,
                                k = t.extend({}, v),
                                T = e(C.my, r.outerWidth(), r.outerHeight());
                            'right' === n.my[0] ? (k.left -= u) : 'center' === n.my[0] && (k.left -= u / 2),
                                'bottom' === n.my[1] ? (k.top -= h) : 'center' === n.my[1] && (k.top -= h / 2),
                                (k.left += T[0]),
                                (k.top += T[1]),
                                (s = {marginLeft: d, marginTop: g}),
                                t.each(['left', 'top'], function (e, i) {
                                    t.ui.position[w[e]] &&
                                        t.ui.position[w[e]][i](k, {
                                            targetWidth: p,
                                            targetHeight: m,
                                            elemWidth: u,
                                            elemHeight: h,
                                            collisionPosition: s,
                                            collisionWidth: x,
                                            collisionHeight: E,
                                            offset: [c[0] + T[0], c[1] + T[1]],
                                            my: n.my,
                                            at: n.at,
                                            within: y,
                                            elem: r,
                                        });
                                }),
                                n.using &&
                                    (l = function (t) {
                                        var e = f.left - k.left,
                                            i = e + p - u,
                                            s = f.top - k.top,
                                            l = s + m - h,
                                            c = {
                                                target: {element: _, left: f.left, top: f.top, width: p, height: m},
                                                element: {element: r, left: k.left, top: k.top, width: u, height: h},
                                                horizontal: 0 > i ? 'left' : e > 0 ? 'right' : 'center',
                                                vertical: 0 > l ? 'top' : s > 0 ? 'bottom' : 'middle',
                                            };
                                        u > p && p > a(e + i) && (c.horizontal = 'center'),
                                            h > m && m > a(s + l) && (c.vertical = 'middle'),
                                            (c.important = o(a(e), a(i)) > o(a(s), a(l)) ? 'horizontal' : 'vertical'),
                                            n.using.call(this, t, c);
                                    }),
                                r.offset(t.extend(k, {using: l}));
                        })
                    );
                }),
                (t.ui.position = {
                    fit: {
                        left: function (t, e) {
                            var i,
                                s = e.within,
                                n = s.isWindow ? s.scrollLeft : s.offset.left,
                                a = s.width,
                                l = t.left - e.collisionPosition.marginLeft,
                                r = n - l,
                                u = l + e.collisionWidth - a - n;
                            e.collisionWidth > a
                                ? r > 0 && 0 >= u
                                    ? ((i = t.left + r + e.collisionWidth - a - n), (t.left += r - i))
                                    : (t.left = u > 0 && 0 >= r ? n : r > u ? n + a - e.collisionWidth : n)
                                : r > 0
                                ? (t.left += r)
                                : u > 0
                                ? (t.left -= u)
                                : (t.left = o(t.left - l, t.left));
                        },
                        top: function (t, e) {
                            var i,
                                s = e.within,
                                n = s.isWindow ? s.scrollTop : s.offset.top,
                                a = e.within.height,
                                l = t.top - e.collisionPosition.marginTop,
                                r = n - l,
                                u = l + e.collisionHeight - a - n;
                            e.collisionHeight > a
                                ? r > 0 && 0 >= u
                                    ? ((i = t.top + r + e.collisionHeight - a - n), (t.top += r - i))
                                    : (t.top = u > 0 && 0 >= r ? n : r > u ? n + a - e.collisionHeight : n)
                                : r > 0
                                ? (t.top += r)
                                : u > 0
                                ? (t.top -= u)
                                : (t.top = o(t.top - l, t.top));
                        },
                    },
                    flip: {
                        left: function (t, e) {
                            var i,
                                s,
                                n = e.within,
                                o = n.offset.left + n.scrollLeft,
                                l = n.width,
                                r = n.isWindow ? n.scrollLeft : n.offset.left,
                                u = t.left - e.collisionPosition.marginLeft,
                                h = u - r,
                                c = u + e.collisionWidth - l - r,
                                d = 'left' === e.my[0] ? -e.elemWidth : 'right' === e.my[0] ? e.elemWidth : 0,
                                p = 'left' === e.at[0] ? e.targetWidth : 'right' === e.at[0] ? -e.targetWidth : 0,
                                m = -2 * e.offset[0];
                            0 > h
                                ? ((i = t.left + d + p + m + e.collisionWidth - l - o),
                                  (0 > i || a(h) > i) && (t.left += d + p + m))
                                : c > 0 &&
                                  ((s = t.left - e.collisionPosition.marginLeft + d + p + m - r),
                                  (s > 0 || c > a(s)) && (t.left += d + p + m));
                        },
                        top: function (t, e) {
                            var i,
                                s,
                                n = e.within,
                                o = n.offset.top + n.scrollTop,
                                l = n.height,
                                r = n.isWindow ? n.scrollTop : n.offset.top,
                                u = t.top - e.collisionPosition.marginTop,
                                h = u - r,
                                c = u + e.collisionHeight - l - r,
                                d = 'top' === e.my[1],
                                p = d ? -e.elemHeight : 'bottom' === e.my[1] ? e.elemHeight : 0,
                                m = 'top' === e.at[1] ? e.targetHeight : 'bottom' === e.at[1] ? -e.targetHeight : 0,
                                f = -2 * e.offset[1];
                            0 > h
                                ? ((s = t.top + p + m + f + e.collisionHeight - l - o),
                                  (0 > s || a(h) > s) && (t.top += p + m + f))
                                : c > 0 &&
                                  ((i = t.top - e.collisionPosition.marginTop + p + m + f - r),
                                  (i > 0 || c > a(i)) && (t.top += p + m + f));
                        },
                    },
                    flipfit: {
                        left: function () {
                            t.ui.position.flip.left.apply(this, arguments),
                                t.ui.position.fit.left.apply(this, arguments);
                        },
                        top: function () {
                            t.ui.position.flip.top.apply(this, arguments), t.ui.position.fit.top.apply(this, arguments);
                        },
                    },
                });
        })(),
        t.ui.position,
        (t.ui.keyCode = {
            BACKSPACE: 8,
            COMMA: 188,
            DELETE: 46,
            DOWN: 40,
            END: 35,
            ENTER: 13,
            ESCAPE: 27,
            HOME: 36,
            LEFT: 37,
            PAGE_DOWN: 34,
            PAGE_UP: 33,
            PERIOD: 190,
            RIGHT: 39,
            SPACE: 32,
            TAB: 9,
            UP: 38,
        }),
        t.fn.extend({
            uniqueId: (function () {
                var t = 0;
                return function () {
                    return this.each(function () {
                        this.id || (this.id = 'ui-id-' + ++t);
                    });
                };
            })(),
            removeUniqueId: function () {
                return this.each(function () {
                    /^ui-id-\d+$/.test(this.id) && t(this).removeAttr('id');
                });
            },
        }),
        (t.ui.safeActiveElement = function (t) {
            var e;
            try {
                e = t.activeElement;
            } catch (i) {
                e = t.body;
            }
            return e || (e = t.body), e.nodeName || (e = t.body), e;
        }),
        t.widget('ui.menu', {
            version: '1.12.1',
            defaultElement: '<ul>',
            delay: 300,
            options: {
                icons: {submenu: 'ui-icon-caret-1-e'},
                items: '> *',
                menus: 'ul',
                position: {my: 'left top', at: 'right top'},
                role: 'menu',
                blur: null,
                focus: null,
                select: null,
            },
            _create: function () {
                (this.activeMenu = this.element),
                    (this.mouseHandled = !1),
                    this.element.uniqueId().attr({role: this.options.role, tabIndex: 0}),
                    this._addClass('ui-menu', 'ui-widget ui-widget-content'),
                    this._on({
                        'mousedown .ui-menu-item': function (t) {
                            t.preventDefault();
                        },
                        'click .ui-menu-item': function (e) {
                            var i = t(e.target),
                                s = t(t.ui.safeActiveElement(this.document[0]));
                            !this.mouseHandled &&
                                i.not('.ui-state-disabled').length &&
                                (this.select(e),
                                e.isPropagationStopped() || (this.mouseHandled = !0),
                                i.has('.ui-menu').length
                                    ? this.expand(e)
                                    : !this.element.is(':focus') &&
                                      s.closest('.ui-menu').length &&
                                      (this.element.trigger('focus', [!0]),
                                      this.active &&
                                          1 === this.active.parents('.ui-menu').length &&
                                          clearTimeout(this.timer)));
                        },
                        'mouseenter .ui-menu-item': function (e) {
                            if (!this.previousFilter) {
                                var i = t(e.target).closest('.ui-menu-item'),
                                    s = t(e.currentTarget);
                                i[0] === s[0] &&
                                    (this._removeClass(
                                        s.siblings().children('.ui-state-active'),
                                        null,
                                        'ui-state-active'
                                    ),
                                    this.focus(e, s));
                            }
                        },
                        mouseleave: 'collapseAll',
                        'mouseleave .ui-menu': 'collapseAll',
                        focus: function (t, e) {
                            var i = this.active || this.element.find(this.options.items).eq(0);
                            e || this.focus(t, i);
                        },
                        blur: function (e) {
                            this._delay(function () {
                                var i = !t.contains(this.element[0], t.ui.safeActiveElement(this.document[0]));
                                i && this.collapseAll(e);
                            });
                        },
                        keydown: '_keydown',
                    }),
                    this.refresh(),
                    this._on(this.document, {
                        click: function (t) {
                            this._closeOnDocumentClick(t) && this.collapseAll(t), (this.mouseHandled = !1);
                        },
                    });
            },
            _destroy: function () {
                var e = this.element.find('.ui-menu-item').removeAttr('role aria-disabled'),
                    i = e.children('.ui-menu-item-wrapper').removeUniqueId().removeAttr('tabIndex role aria-haspopup');
                this.element
                    .removeAttr('aria-activedescendant')
                    .find('.ui-menu')
                    .addBack()
                    .removeAttr('role aria-labelledby aria-expanded aria-hidden aria-disabled tabIndex')
                    .removeUniqueId()
                    .show(),
                    i.children().each(function () {
                        var e = t(this);
                        e.data('ui-menu-submenu-caret') && e.remove();
                    });
            },
            _keydown: function (e) {
                var i,
                    s,
                    n,
                    o,
                    a = !0;
                switch (e.keyCode) {
                    case t.ui.keyCode.PAGE_UP:
                        this.previousPage(e);
                        break;
                    case t.ui.keyCode.PAGE_DOWN:
                        this.nextPage(e);
                        break;
                    case t.ui.keyCode.HOME:
                        this._move('first', 'first', e);
                        break;
                    case t.ui.keyCode.END:
                        this._move('last', 'last', e);
                        break;
                    case t.ui.keyCode.UP:
                        this.previous(e);
                        break;
                    case t.ui.keyCode.DOWN:
                        this.next(e);
                        break;
                    case t.ui.keyCode.LEFT:
                        this.collapse(e);
                        break;
                    case t.ui.keyCode.RIGHT:
                        this.active && !this.active.is('.ui-state-disabled') && this.expand(e);
                        break;
                    case t.ui.keyCode.ENTER:
                    case t.ui.keyCode.SPACE:
                        this._activate(e);
                        break;
                    case t.ui.keyCode.ESCAPE:
                        this.collapse(e);
                        break;
                    default:
                        (a = !1),
                            (s = this.previousFilter || ''),
                            (o = !1),
                            (n =
                                e.keyCode >= 96 && 105 >= e.keyCode
                                    ? '' + (e.keyCode - 96)
                                    : String.fromCharCode(e.keyCode)),
                            clearTimeout(this.filterTimer),
                            n === s ? (o = !0) : (n = s + n),
                            (i = this._filterMenuItems(n)),
                            (i = o && -1 !== i.index(this.active.next()) ? this.active.nextAll('.ui-menu-item') : i),
                            i.length || ((n = String.fromCharCode(e.keyCode)), (i = this._filterMenuItems(n))),
                            i.length
                                ? (this.focus(e, i),
                                  (this.previousFilter = n),
                                  (this.filterTimer = this._delay(function () {
                                      delete this.previousFilter;
                                  }, 1e3)))
                                : delete this.previousFilter;
                }
                a && e.preventDefault();
            },
            _activate: function (t) {
                this.active &&
                    !this.active.is('.ui-state-disabled') &&
                    (this.active.children("[aria-haspopup='true']").length ? this.expand(t) : this.select(t));
            },
            refresh: function () {
                var e,
                    i,
                    s,
                    n,
                    o,
                    a = this,
                    l = this.options.icons.submenu,
                    r = this.element.find(this.options.menus);
                this._toggleClass('ui-menu-icons', null, !!this.element.find('.ui-icon').length),
                    (s = r
                        .filter(':not(.ui-menu)')
                        .hide()
                        .attr({role: this.options.role, 'aria-hidden': 'true', 'aria-expanded': 'false'})
                        .each(function () {
                            var e = t(this),
                                i = e.prev(),
                                s = t('<span>').data('ui-menu-submenu-caret', !0);
                            a._addClass(s, 'ui-menu-icon', 'ui-icon ' + l),
                                i.attr('aria-haspopup', 'true').prepend(s),
                                e.attr('aria-labelledby', i.attr('id'));
                        })),
                    this._addClass(s, 'ui-menu', 'ui-widget ui-widget-content ui-front'),
                    (e = r.add(this.element)),
                    (i = e.find(this.options.items)),
                    i.not('.ui-menu-item').each(function () {
                        var e = t(this);
                        a._isDivider(e) && a._addClass(e, 'ui-menu-divider', 'ui-widget-content');
                    }),
                    (n = i.not('.ui-menu-item, .ui-menu-divider')),
                    (o = n.children().not('.ui-menu').uniqueId().attr({tabIndex: -1, role: this._itemRole()})),
                    this._addClass(n, 'ui-menu-item')._addClass(o, 'ui-menu-item-wrapper'),
                    i.filter('.ui-state-disabled').attr('aria-disabled', 'true'),
                    this.active && !t.contains(this.element[0], this.active[0]) && this.blur();
            },
            _itemRole: function () {
                return {menu: 'menuitem', listbox: 'option'}[this.options.role];
            },
            _setOption: function (t, e) {
                if ('icons' === t) {
                    var i = this.element.find('.ui-menu-icon');
                    this._removeClass(i, null, this.options.icons.submenu)._addClass(i, null, e.submenu);
                }
                this._super(t, e);
            },
            _setOptionDisabled: function (t) {
                this._super(t),
                    this.element.attr('aria-disabled', t + ''),
                    this._toggleClass(null, 'ui-state-disabled', !!t);
            },
            focus: function (t, e) {
                var i, s, n;
                this.blur(t, t && 'focus' === t.type),
                    this._scrollIntoView(e),
                    (this.active = e.first()),
                    (s = this.active.children('.ui-menu-item-wrapper')),
                    this._addClass(s, null, 'ui-state-active'),
                    this.options.role && this.element.attr('aria-activedescendant', s.attr('id')),
                    (n = this.active.parent().closest('.ui-menu-item').children('.ui-menu-item-wrapper')),
                    this._addClass(n, null, 'ui-state-active'),
                    t && 'keydown' === t.type
                        ? this._close()
                        : (this.timer = this._delay(function () {
                              this._close();
                          }, this.delay)),
                    (i = e.children('.ui-menu')),
                    i.length && t && /^mouse/.test(t.type) && this._startOpening(i),
                    (this.activeMenu = e.parent()),
                    this._trigger('focus', t, {item: e});
            },
            _scrollIntoView: function (e) {
                var i, s, n, o, a, l;
                this._hasScroll() &&
                    ((i = parseFloat(t.css(this.activeMenu[0], 'borderTopWidth')) || 0),
                    (s = parseFloat(t.css(this.activeMenu[0], 'paddingTop')) || 0),
                    (n = e.offset().top - this.activeMenu.offset().top - i - s),
                    (o = this.activeMenu.scrollTop()),
                    (a = this.activeMenu.height()),
                    (l = e.outerHeight()),
                    0 > n ? this.activeMenu.scrollTop(o + n) : n + l > a && this.activeMenu.scrollTop(o + n - a + l));
            },
            blur: function (t, e) {
                e || clearTimeout(this.timer),
                    this.active &&
                        (this._removeClass(this.active.children('.ui-menu-item-wrapper'), null, 'ui-state-active'),
                        this._trigger('blur', t, {item: this.active}),
                        (this.active = null));
            },
            _startOpening: function (t) {
                clearTimeout(this.timer),
                    'true' === t.attr('aria-hidden') &&
                        (this.timer = this._delay(function () {
                            this._close(), this._open(t);
                        }, this.delay));
            },
            _open: function (e) {
                var i = t.extend({of: this.active}, this.options.position);
                clearTimeout(this.timer),
                    this.element.find('.ui-menu').not(e.parents('.ui-menu')).hide().attr('aria-hidden', 'true'),
                    e.show().removeAttr('aria-hidden').attr('aria-expanded', 'true').position(i);
            },
            collapseAll: function (e, i) {
                clearTimeout(this.timer),
                    (this.timer = this._delay(function () {
                        var s = i ? this.element : t(e && e.target).closest(this.element.find('.ui-menu'));
                        s.length || (s = this.element),
                            this._close(s),
                            this.blur(e),
                            this._removeClass(s.find('.ui-state-active'), null, 'ui-state-active'),
                            (this.activeMenu = s);
                    }, this.delay));
            },
            _close: function (t) {
                t || (t = this.active ? this.active.parent() : this.element),
                    t.find('.ui-menu').hide().attr('aria-hidden', 'true').attr('aria-expanded', 'false');
            },
            _closeOnDocumentClick: function (e) {
                return !t(e.target).closest('.ui-menu').length;
            },
            _isDivider: function (t) {
                return !/[^\-\u2014\u2013\s]/.test(t.text());
            },
            collapse: function (t) {
                var e = this.active && this.active.parent().closest('.ui-menu-item', this.element);
                e && e.length && (this._close(), this.focus(t, e));
            },
            expand: function (t) {
                var e = this.active && this.active.children('.ui-menu ').find(this.options.items).first();
                e &&
                    e.length &&
                    (this._open(e.parent()),
                    this._delay(function () {
                        this.focus(t, e);
                    }));
            },
            next: function (t) {
                this._move('next', 'first', t);
            },
            previous: function (t) {
                this._move('prev', 'last', t);
            },
            isFirstItem: function () {
                return this.active && !this.active.prevAll('.ui-menu-item').length;
            },
            isLastItem: function () {
                return this.active && !this.active.nextAll('.ui-menu-item').length;
            },
            _move: function (t, e, i) {
                var s;
                this.active &&
                    (s =
                        'first' === t || 'last' === t
                            ? this.active['first' === t ? 'prevAll' : 'nextAll']('.ui-menu-item').eq(-1)
                            : this.active[t + 'All']('.ui-menu-item').eq(0)),
                    (s && s.length && this.active) || (s = this.activeMenu.find(this.options.items)[e]()),
                    this.focus(i, s);
            },
            nextPage: function (e) {
                var i, s, n;
                return this.active
                    ? (this.isLastItem() ||
                          (this._hasScroll()
                              ? ((s = this.active.offset().top),
                                (n = this.element.height()),
                                this.active.nextAll('.ui-menu-item').each(function () {
                                    return (i = t(this)), 0 > i.offset().top - s - n;
                                }),
                                this.focus(e, i))
                              : this.focus(
                                    e,
                                    this.activeMenu.find(this.options.items)[this.active ? 'last' : 'first']()
                                )),
                      void 0)
                    : (this.next(e), void 0);
            },
            previousPage: function (e) {
                var i, s, n;
                return this.active
                    ? (this.isFirstItem() ||
                          (this._hasScroll()
                              ? ((s = this.active.offset().top),
                                (n = this.element.height()),
                                this.active.prevAll('.ui-menu-item').each(function () {
                                    return (i = t(this)), i.offset().top - s + n > 0;
                                }),
                                this.focus(e, i))
                              : this.focus(e, this.activeMenu.find(this.options.items).first())),
                      void 0)
                    : (this.next(e), void 0);
            },
            _hasScroll: function () {
                return this.element.outerHeight() < this.element.prop('scrollHeight');
            },
            select: function (e) {
                this.active = this.active || t(e.target).closest('.ui-menu-item');
                var i = {item: this.active};
                this.active.has('.ui-menu').length || this.collapseAll(e, !0), this._trigger('select', e, i);
            },
            _filterMenuItems: function (e) {
                var i = e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&'),
                    s = RegExp('^' + i, 'i');
                return this.activeMenu
                    .find(this.options.items)
                    .filter('.ui-menu-item')
                    .filter(function () {
                        return s.test(t.trim(t(this).children('.ui-menu-item-wrapper').text()));
                    });
            },
        }),
        t.widget('ui.autocomplete', {
            version: '1.12.1',
            defaultElement: '<input>',
            options: {
                appendTo: null,
                autoFocus: !1,
                delay: 300,
                minLength: 1,
                position: {my: 'left top', at: 'left bottom', collision: 'none'},
                source: null,
                change: null,
                close: null,
                focus: null,
                open: null,
                response: null,
                search: null,
                select: null,
            },
            requestIndex: 0,
            pending: 0,
            _create: function () {
                var e,
                    i,
                    s,
                    n = this.element[0].nodeName.toLowerCase(),
                    o = 'textarea' === n,
                    a = 'input' === n;
                (this.isMultiLine = o || (!a && this._isContentEditable(this.element))),
                    (this.valueMethod = this.element[o || a ? 'val' : 'text']),
                    (this.isNewMenu = !0),
                    this._addClass('ui-autocomplete-input'),
                    this.element.attr('autocomplete', 'off'),
                    this._on(this.element, {
                        keydown: function (n) {
                            if (this.element.prop('readOnly')) return (e = !0), (s = !0), (i = !0), void 0;
                            (e = !1), (s = !1), (i = !1);
                            var o = t.ui.keyCode;
                            switch (n.keyCode) {
                                case o.PAGE_UP:
                                    (e = !0), this._move('previousPage', n);
                                    break;
                                case o.PAGE_DOWN:
                                    (e = !0), this._move('nextPage', n);
                                    break;
                                case o.UP:
                                    (e = !0), this._keyEvent('previous', n);
                                    break;
                                case o.DOWN:
                                    (e = !0), this._keyEvent('next', n);
                                    break;
                                case o.ENTER:
                                    this.menu.active && ((e = !0), n.preventDefault(), this.menu.select(n));
                                    break;
                                case o.TAB:
                                    this.menu.active && this.menu.select(n);
                                    break;
                                case o.ESCAPE:
                                    this.menu.element.is(':visible') &&
                                        (this.isMultiLine || this._value(this.term), this.close(n), n.preventDefault());
                                    break;
                                default:
                                    (i = !0), this._searchTimeout(n);
                            }
                        },
                        keypress: function (s) {
                            if (e)
                                return (
                                    (e = !1),
                                    (!this.isMultiLine || this.menu.element.is(':visible')) && s.preventDefault(),
                                    void 0
                                );
                            if (!i) {
                                var n = t.ui.keyCode;
                                switch (s.keyCode) {
                                    case n.PAGE_UP:
                                        this._move('previousPage', s);
                                        break;
                                    case n.PAGE_DOWN:
                                        this._move('nextPage', s);
                                        break;
                                    case n.UP:
                                        this._keyEvent('previous', s);
                                        break;
                                    case n.DOWN:
                                        this._keyEvent('next', s);
                                }
                            }
                        },
                        input: function (t) {
                            return s ? ((s = !1), t.preventDefault(), void 0) : (this._searchTimeout(t), void 0);
                        },
                        focus: function () {
                            (this.selectedItem = null), (this.previous = this._value());
                        },
                        blur: function (t) {
                            return this.cancelBlur
                                ? (delete this.cancelBlur, void 0)
                                : (clearTimeout(this.searching), this.close(t), this._change(t), void 0);
                        },
                    }),
                    this._initSource(),
                    (this.menu = t('<ul>').appendTo(this._appendTo()).menu({role: null}).hide().menu('instance')),
                    this._addClass(this.menu.element, 'ui-autocomplete', 'ui-front'),
                    this._on(this.menu.element, {
                        mousedown: function (e) {
                            e.preventDefault(),
                                (this.cancelBlur = !0),
                                this._delay(function () {
                                    delete this.cancelBlur,
                                        this.element[0] !== t.ui.safeActiveElement(this.document[0]) &&
                                            this.element.trigger('focus');
                                });
                        },
                        menufocus: function (e, i) {
                            var s, n;
                            return this.isNewMenu &&
                                ((this.isNewMenu = !1), e.originalEvent && /^mouse/.test(e.originalEvent.type))
                                ? (this.menu.blur(),
                                  this.document.one('mousemove', function () {
                                      t(e.target).trigger(e.originalEvent);
                                  }),
                                  void 0)
                                : ((n = i.item.data('ui-autocomplete-item')),
                                  !1 !== this._trigger('focus', e, {item: n}) &&
                                      e.originalEvent &&
                                      /^key/.test(e.originalEvent.type) &&
                                      this._value(n.value),
                                  (s = i.item.attr('aria-label') || n.value),
                                  s &&
                                      t.trim(s).length &&
                                      (this.liveRegion.children().hide(), t('<div>').text(s).appendTo(this.liveRegion)),
                                  void 0);
                        },
                        menuselect: function (e, i) {
                            var s = i.item.data('ui-autocomplete-item'),
                                n = this.previous;
                            this.element[0] !== t.ui.safeActiveElement(this.document[0]) &&
                                (this.element.trigger('focus'),
                                (this.previous = n),
                                this._delay(function () {
                                    (this.previous = n), (this.selectedItem = s);
                                })),
                                !1 !== this._trigger('select', e, {item: s}) && this._value(s.value),
                                (this.term = this._value()),
                                this.close(e),
                                (this.selectedItem = s);
                        },
                    }),
                    (this.liveRegion = t('<div>', {
                        role: 'status',
                        'aria-live': 'assertive',
                        'aria-relevant': 'additions',
                    }).appendTo(this.document[0].body)),
                    this._addClass(this.liveRegion, null, 'ui-helper-hidden-accessible'),
                    this._on(this.window, {
                        beforeunload: function () {
                            this.element.removeAttr('autocomplete');
                        },
                    });
            },
            _destroy: function () {
                clearTimeout(this.searching),
                    this.element.removeAttr('autocomplete'),
                    this.menu.element.remove(),
                    this.liveRegion.remove();
            },
            _setOption: function (t, e) {
                this._super(t, e),
                    'source' === t && this._initSource(),
                    'appendTo' === t && this.menu.element.appendTo(this._appendTo()),
                    'disabled' === t && e && this.xhr && this.xhr.abort();
            },
            _isEventTargetInWidget: function (e) {
                var i = this.menu.element[0];
                return e.target === this.element[0] || e.target === i || t.contains(i, e.target);
            },
            _closeOnClickOutside: function (t) {
                this._isEventTargetInWidget(t) || this.close();
            },
            _appendTo: function () {
                var e = this.options.appendTo;
                return (
                    e && (e = e.jquery || e.nodeType ? t(e) : this.document.find(e).eq(0)),
                    (e && e[0]) || (e = this.element.closest('.ui-front, dialog')),
                    e.length || (e = this.document[0].body),
                    e
                );
            },
            _initSource: function () {
                var e,
                    i,
                    s = this;
                t.isArray(this.options.source)
                    ? ((e = this.options.source),
                      (this.source = function (i, s) {
                          s(t.ui.autocomplete.filter(e, i.term));
                      }))
                    : 'string' == typeof this.options.source
                    ? ((i = this.options.source),
                      (this.source = function (e, n) {
                          s.xhr && s.xhr.abort(),
                              (s.xhr = t.ajax({
                                  url: i,
                                  data: e,
                                  dataType: 'json',
                                  success: function (t) {
                                      n(t);
                                  },
                                  error: function () {
                                      n([]);
                                  },
                              }));
                      }))
                    : (this.source = this.options.source);
            },
            _searchTimeout: function (t) {
                clearTimeout(this.searching),
                    (this.searching = this._delay(function () {
                        var e = this.term === this._value(),
                            i = this.menu.element.is(':visible'),
                            s = t.altKey || t.ctrlKey || t.metaKey || t.shiftKey;
                        (!e || (e && !i && !s)) && ((this.selectedItem = null), this.search(null, t));
                    }, this.options.delay));
            },
            search: function (t, e) {
                return (
                    (t = null != t ? t : this._value()),
                    (this.term = this._value()),
                    t.length < this.options.minLength
                        ? this.close(e)
                        : this._trigger('search', e) !== !1
                        ? this._search(t)
                        : void 0
                );
            },
            _search: function (t) {
                this.pending++,
                    this._addClass('ui-autocomplete-loading'),
                    (this.cancelSearch = !1),
                    this.source({term: t}, this._response());
            },
            _response: function () {
                var e = ++this.requestIndex;
                return t.proxy(function (t) {
                    e === this.requestIndex && this.__response(t),
                        this.pending--,
                        this.pending || this._removeClass('ui-autocomplete-loading');
                }, this);
            },
            __response: function (t) {
                t && (t = this._normalize(t)),
                    this._trigger('response', null, {content: t}),
                    !this.options.disabled && t && t.length && !this.cancelSearch
                        ? (this._suggest(t), this._trigger('open'))
                        : this._close();
            },
            close: function (t) {
                (this.cancelSearch = !0), this._close(t);
            },
            _close: function (t) {
                this._off(this.document, 'mousedown'),
                    this.menu.element.is(':visible') &&
                        (this.menu.element.hide(), this.menu.blur(), (this.isNewMenu = !0), this._trigger('close', t));
            },
            _change: function (t) {
                this.previous !== this._value() && this._trigger('change', t, {item: this.selectedItem});
            },
            _normalize: function (e) {
                return e.length && e[0].label && e[0].value
                    ? e
                    : t.map(e, function (e) {
                          return 'string' == typeof e
                              ? {label: e, value: e}
                              : t.extend({}, e, {label: e.label || e.value, value: e.value || e.label});
                      });
            },
            _suggest: function (e) {
                var i = this.menu.element.empty();
                this._renderMenu(i, e),
                    (this.isNewMenu = !0),
                    this.menu.refresh(),
                    i.show(),
                    this._resizeMenu(),
                    i.position(t.extend({of: this.element}, this.options.position)),
                    this.options.autoFocus && this.menu.next(),
                    this._on(this.document, {mousedown: '_closeOnClickOutside'});
            },
            _resizeMenu: function () {
                var t = this.menu.element;
                t.outerWidth(Math.max(t.width('').outerWidth() + 1, this.element.outerWidth()));
            },
            _renderMenu: function (e, i) {
                var s = this;
                t.each(i, function (t, i) {
                    s._renderItemData(e, i);
                });
            },
            _renderItemData: function (t, e) {
                return this._renderItem(t, e).data('ui-autocomplete-item', e);
            },
            _renderItem: function (e, i) {
                return t('<li>').append(t('<div>').text(i.label)).appendTo(e);
            },
            _move: function (t, e) {
                return this.menu.element.is(':visible')
                    ? (this.menu.isFirstItem() && /^previous/.test(t)) || (this.menu.isLastItem() && /^next/.test(t))
                        ? (this.isMultiLine || this._value(this.term), this.menu.blur(), void 0)
                        : (this.menu[t](e), void 0)
                    : (this.search(null, e), void 0);
            },
            widget: function () {
                return this.menu.element;
            },
            _value: function () {
                return this.valueMethod.apply(this.element, arguments);
            },
            _keyEvent: function (t, e) {
                (!this.isMultiLine || this.menu.element.is(':visible')) && (this._move(t, e), e.preventDefault());
            },
            _isContentEditable: function (t) {
                if (!t.length) return !1;
                var e = t.prop('contentEditable');
                return 'inherit' === e ? this._isContentEditable(t.parent()) : 'true' === e;
            },
        }),
        t.extend(t.ui.autocomplete, {
            escapeRegex: function (t) {
                return t.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
            },
            filter: function (e, i) {
                var s = RegExp(t.ui.autocomplete.escapeRegex(i), 'i');
                return t.grep(e, function (t) {
                    return s.test(t.label || t.value || t);
                });
            },
        }),
        t.widget('ui.autocomplete', t.ui.autocomplete, {
            options: {
                messages: {
                    noResults: 'No search results.',
                    results: function (t) {
                        return (
                            t +
                            (t > 1 ? ' results are' : ' result is') +
                            ' available, use up and down arrow keys to navigate.'
                        );
                    },
                },
            },
            __response: function (e) {
                var i;
                this._superApply(arguments),
                    this.options.disabled ||
                        this.cancelSearch ||
                        ((i =
                            e && e.length ? this.options.messages.results(e.length) : this.options.messages.noResults),
                        this.liveRegion.children().hide(),
                        t('<div>').text(i).appendTo(this.liveRegion));
            },
        }),
        t.ui.autocomplete,
        t.widget('ui.tooltip', {
            version: '1.12.1',
            options: {
                classes: {'ui-tooltip': 'ui-corner-all ui-widget-shadow'},
                content: function () {
                    var e = t(this).attr('title') || '';
                    return t('<a>').text(e).html();
                },
                hide: !0,
                items: '[title]:not([disabled])',
                position: {my: 'left top+15', at: 'left bottom', collision: 'flipfit flip'},
                show: !0,
                track: !1,
                close: null,
                open: null,
            },
            _addDescribedBy: function (e, i) {
                var s = (e.attr('aria-describedby') || '').split(/\s+/);
                s.push(i), e.data('ui-tooltip-id', i).attr('aria-describedby', t.trim(s.join(' ')));
            },
            _removeDescribedBy: function (e) {
                var i = e.data('ui-tooltip-id'),
                    s = (e.attr('aria-describedby') || '').split(/\s+/),
                    n = t.inArray(i, s);
                -1 !== n && s.splice(n, 1),
                    e.removeData('ui-tooltip-id'),
                    (s = t.trim(s.join(' '))),
                    s ? e.attr('aria-describedby', s) : e.removeAttr('aria-describedby');
            },
            _create: function () {
                this._on({mouseover: 'open', focusin: 'open'}),
                    (this.tooltips = {}),
                    (this.parents = {}),
                    (this.liveRegion = t('<div>')
                        .attr({role: 'log', 'aria-live': 'assertive', 'aria-relevant': 'additions'})
                        .appendTo(this.document[0].body)),
                    this._addClass(this.liveRegion, null, 'ui-helper-hidden-accessible'),
                    (this.disabledTitles = t([]));
            },
            _setOption: function (e, i) {
                var s = this;
                this._super(e, i),
                    'content' === e &&
                        t.each(this.tooltips, function (t, e) {
                            s._updateContent(e.element);
                        });
            },
            _setOptionDisabled: function (t) {
                this[t ? '_disable' : '_enable']();
            },
            _disable: function () {
                var e = this;
                t.each(this.tooltips, function (i, s) {
                    var n = t.Event('blur');
                    (n.target = n.currentTarget = s.element[0]), e.close(n, !0);
                }),
                    (this.disabledTitles = this.disabledTitles.add(
                        this.element
                            .find(this.options.items)
                            .addBack()
                            .filter(function () {
                                var e = t(this);
                                return e.is('[title]')
                                    ? e.data('ui-tooltip-title', e.attr('title')).removeAttr('title')
                                    : void 0;
                            })
                    ));
            },
            _enable: function () {
                this.disabledTitles.each(function () {
                    var e = t(this);
                    e.data('ui-tooltip-title') && e.attr('title', e.data('ui-tooltip-title'));
                }),
                    (this.disabledTitles = t([]));
            },
            open: function (e) {
                var i = this,
                    s = t(e ? e.target : this.element).closest(this.options.items);
                s.length &&
                    !s.data('ui-tooltip-id') &&
                    (s.attr('title') && s.data('ui-tooltip-title', s.attr('title')),
                    s.data('ui-tooltip-open', !0),
                    e &&
                        'mouseover' === e.type &&
                        s.parents().each(function () {
                            var e,
                                s = t(this);
                            s.data('ui-tooltip-open') &&
                                ((e = t.Event('blur')), (e.target = e.currentTarget = this), i.close(e, !0)),
                                s.attr('title') &&
                                    (s.uniqueId(),
                                    (i.parents[this.id] = {element: this, title: s.attr('title')}),
                                    s.attr('title', ''));
                        }),
                    this._registerCloseHandlers(e, s),
                    this._updateContent(s, e));
            },
            _updateContent: function (t, e) {
                var i,
                    s = this.options.content,
                    n = this,
                    o = e ? e.type : null;
                return 'string' == typeof s || s.nodeType || s.jquery
                    ? this._open(e, t, s)
                    : ((i = s.call(t[0], function (i) {
                          n._delay(function () {
                              t.data('ui-tooltip-open') && (e && (e.type = o), this._open(e, t, i));
                          });
                      })),
                      i && this._open(e, t, i),
                      void 0);
            },
            _open: function (e, i, s) {
                function n(t) {
                    (u.of = t), a.is(':hidden') || a.position(u);
                }
                var o,
                    a,
                    l,
                    r,
                    u = t.extend({}, this.options.position);
                if (s) {
                    if ((o = this._find(i))) return o.tooltip.find('.ui-tooltip-content').html(s), void 0;
                    i.is('[title]') && (e && 'mouseover' === e.type ? i.attr('title', '') : i.removeAttr('title')),
                        (o = this._tooltip(i)),
                        (a = o.tooltip),
                        this._addDescribedBy(i, a.attr('id')),
                        a.find('.ui-tooltip-content').html(s),
                        this.liveRegion.children().hide(),
                        (r = t('<div>').html(a.find('.ui-tooltip-content').html())),
                        r.removeAttr('name').find('[name]').removeAttr('name'),
                        r.removeAttr('id').find('[id]').removeAttr('id'),
                        r.appendTo(this.liveRegion),
                        this.options.track && e && /^mouse/.test(e.type)
                            ? (this._on(this.document, {mousemove: n}), n(e))
                            : a.position(t.extend({of: i}, this.options.position)),
                        a.hide(),
                        this._show(a, this.options.show),
                        this.options.track &&
                            this.options.show &&
                            this.options.show.delay &&
                            (l = this.delayedShow =
                                setInterval(function () {
                                    a.is(':visible') && (n(u.of), clearInterval(l));
                                }, t.fx.interval)),
                        this._trigger('open', e, {tooltip: a});
                }
            },
            _registerCloseHandlers: function (e, i) {
                var s = {
                    keyup: function (e) {
                        if (e.keyCode === t.ui.keyCode.ESCAPE) {
                            var s = t.Event(e);
                            (s.currentTarget = i[0]), this.close(s, !0);
                        }
                    },
                };
                i[0] !== this.element[0] &&
                    (s.remove = function () {
                        this._removeTooltip(this._find(i).tooltip);
                    }),
                    (e && 'mouseover' !== e.type) || (s.mouseleave = 'close'),
                    (e && 'focusin' !== e.type) || (s.focusout = 'close'),
                    this._on(!0, i, s);
            },
            close: function (e) {
                var i,
                    s = this,
                    n = t(e ? e.currentTarget : this.element),
                    o = this._find(n);
                return o
                    ? ((i = o.tooltip),
                      o.closing ||
                          (clearInterval(this.delayedShow),
                          n.data('ui-tooltip-title') && !n.attr('title') && n.attr('title', n.data('ui-tooltip-title')),
                          this._removeDescribedBy(n),
                          (o.hiding = !0),
                          i.stop(!0),
                          this._hide(i, this.options.hide, function () {
                              s._removeTooltip(t(this));
                          }),
                          n.removeData('ui-tooltip-open'),
                          this._off(n, 'mouseleave focusout keyup'),
                          n[0] !== this.element[0] && this._off(n, 'remove'),
                          this._off(this.document, 'mousemove'),
                          e &&
                              'mouseleave' === e.type &&
                              t.each(this.parents, function (e, i) {
                                  t(i.element).attr('title', i.title), delete s.parents[e];
                              }),
                          (o.closing = !0),
                          this._trigger('close', e, {tooltip: i}),
                          o.hiding || (o.closing = !1)),
                      void 0)
                    : (n.removeData('ui-tooltip-open'), void 0);
            },
            _tooltip: function (e) {
                var i = t('<div>').attr('role', 'tooltip'),
                    s = t('<div>').appendTo(i),
                    n = i.uniqueId().attr('id');
                return (
                    this._addClass(s, 'ui-tooltip-content'),
                    this._addClass(i, 'ui-tooltip', 'ui-widget ui-widget-content'),
                    i.appendTo(this._appendTo(e)),
                    (this.tooltips[n] = {element: e, tooltip: i})
                );
            },
            _find: function (t) {
                var e = t.data('ui-tooltip-id');
                return e ? this.tooltips[e] : null;
            },
            _removeTooltip: function (t) {
                t.remove(), delete this.tooltips[t.attr('id')];
            },
            _appendTo: function (t) {
                var e = t.closest('.ui-front, dialog');
                return e.length || (e = this.document[0].body), e;
            },
            _destroy: function () {
                var e = this;
                t.each(this.tooltips, function (i, s) {
                    var n = t.Event('blur'),
                        o = s.element;
                    (n.target = n.currentTarget = o[0]),
                        e.close(n, !0),
                        t('#' + i).remove(),
                        o.data('ui-tooltip-title') &&
                            (o.attr('title') || o.attr('title', o.data('ui-tooltip-title')),
                            o.removeData('ui-tooltip-title'));
                }),
                    this.liveRegion.remove();
            },
        }),
        t.uiBackCompat !== !1 &&
            t.widget('ui.tooltip', t.ui.tooltip, {
                options: {tooltipClass: null},
                _tooltip: function () {
                    var t = this._superApply(arguments);
                    return this.options.tooltipClass && t.tooltip.addClass(this.options.tooltipClass), t;
                },
            }),
        t.ui.tooltip;
});
