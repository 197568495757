/*
 * @TODO implement accessibility basic -
 *  x.attr({'aria-expanded': 'false'});
 *  x.attr({'aria-hidden': 'true'});
 *
 * @TODO document
 *
 * */

(function () {
    const viewToggler = {
        selectors: {
            shown: 'js--shown',
            statusShown: 'js--target-shown',
            hidden: 'js--hidden',
            statusHidden: 'js--target-hidden',
            attrToggle: 'data-toggle-target-id',
            attrShow: 'data-show-target-id',
            attrHide: 'data-hide-target-id',
        },
        evs: {
            // events
            beforeShow: 'viewToggler.beforeShow',
            beforeHide: 'viewToggler.beforeHide',
            afterShow: 'viewToggler.afterShow',
            afterHide: 'viewToggler.afterHide',
            triggerHide: 'viewToggler.trigger.hide',
            triggerShow: 'viewToggler.trigger.show',
        },
        showAction(element, trigger) {
            // @TODO - maybe move to a method
            element.classList.remove(viewToggler.selectors.hidden);
            element.classList.add(viewToggler.selectors.shown);
            viewToggler.setFocusIn(element); // moving focus in when the target is open
            document.dispatchEvent(
                new CustomEvent(viewToggler.evs.afterShow, {detail: {element, trigger}, cancelable: false})
            );
        },
        show(element, trigger) {
            if (element) {
                const maybeCancelled = document.dispatchEvent(
                    new CustomEvent(viewToggler.evs.beforeShow, {detail: {element, trigger}, cancelable: true})
                );
                // cancel if need by e.preventDefault

                if (!maybeCancelled) {
                    return;
                }

                // accordion support requires bulletproof js method such as this one:

                if (trigger.classList.contains('accordion') && $ && $.fn) {
                    // @TODO should be probably be cleaned up or in a callback to keep it clean. It's very ux3-ish now :(
                    $(element).slideDown('fast', () => {
                        this.showAction(element, trigger);
                    });
                } else {
                    this.showAction(element, trigger);
                }

                trigger.classList.remove(viewToggler.selectors.statusHidden);
                trigger.classList.add(viewToggler.selectors.statusShown);

                element.originalTrigger = trigger;
                // element.addEventListener('focusout', viewToggler.getFocusoutCallback(element, trigger) )
            }
        },
        hideAction(element, trigger, targetFocus) {
            element.classList.remove(viewToggler.selectors.shown);
            element.classList.add(viewToggler.selectors.hidden);
            document.dispatchEvent(
                new CustomEvent(viewToggler.evs.afterHide, {detail: {element, trigger}, cancelable: false})
            );
            if (element.originalTrigger && targetFocus) {
                element.originalTrigger.focus(); /* @TODO consider prefixing props in the global object */
                // element.removeEventListener('focusout', element.viewTogglerFocusout)
            }
        },
        hide(element, trigger, targetFocus = true) {
            // targetFocus needs to be disabled when called for multiple targets as it can cause page jumps because some of the targets may be out of the window
            if (element) {
                const maybeCancelled = document.dispatchEvent(
                    new CustomEvent(viewToggler.evs.beforeHide, {detail: {element, trigger}, cancelable: true})
                );
                // Note that custom events polyfill is already add for the breakpoints event in the UX Enquire

                if (!maybeCancelled) {
                    return;
                }

                if (trigger.classList.contains('accordion') && $ && $.fn) {
                    // @TODO should be probably be cleaned up or in a callback to keep it cleam
                    $(element).slideUp('fast', () => {
                        this.hideAction(element, trigger, targetFocus);
                    });
                } else {
                    this.hideAction(element, trigger, targetFocus);
                }

                trigger.classList.remove(viewToggler.selectors.statusShown);
                trigger.classList.add(viewToggler.selectors.statusHidden);
            }
        },
        parentByAttribute(el, attribute) {
            let count = 0;

            function traverseUp(el) {
                if (count >= 5 || !el.parentElement) {
                    // Assuming that under no circumstances can a close/open button have more than 5 DOM layers and there is no need to traverse all the way up to the body.
                    return null;
                }
                if (el.parentElement.getAttribute(attribute)) {
                    return el.parentElement;
                } else {
                    count++;
                    return traverseUp(el.parentElement);
                }
            }

            return traverseUp(el);
        },
        getTarget(el, attr) {
            if (el.getAttribute(attr)) {
                return el;
            }
            const parent = viewToggler.parentByAttribute(el, attr);
            if (parent) {
                return parent;
            }
        },

        hideAll() {
            /* @TODO check if really works properly */
            let shownItems = document.querySelectorAll('.' + viewToggler.selectors.shown);
            Array.prototype.slice.call(shownItems).map(item => {
                item.classList.remove(viewToggler.selectors.shown);
                item.classList.add(viewToggler.selectors.hidden);
            });
            let triggers = document.querySelectorAll('.' + viewToggler.selectors.statusShown);
            triggers = Array.prototype.slice.call(triggers).map((item, i) => {
                item.classList.remove(viewToggler.selectors.statusShown);
                if (triggers.length - 1 === i) {
                    item.focus(); // putting focus on the last trigger in set to avoid resetting tabulation order
                }
            });
        },

        control(e) {
            // @TODO Shouldn't every case return to stop function execution?

            /* Showing case */
            const showTrigger = viewToggler.getTarget(e.target, viewToggler.selectors.attrShow);
            if (showTrigger) {
                e.preventDefault();

                const targetEl = document.getElementById(showTrigger.getAttribute(viewToggler.selectors.attrShow));
                viewToggler.show(targetEl, showTrigger);
            }
            /* Hiding case */
            const hideTrigger = viewToggler.getTarget(e.target, viewToggler.selectors.attrHide);
            if (hideTrigger) {
                e.preventDefault();

                const targetEl = document.getElementById(hideTrigger.getAttribute(viewToggler.selectors.attrHide));
                viewToggler.hide(targetEl, hideTrigger);
            }
            /* Toggling case */
            const toggleTrigger = viewToggler.getTarget(e.target, viewToggler.selectors.attrToggle);
            if (toggleTrigger) {
                e.preventDefault();
                const targetEl = document.getElementById(toggleTrigger.getAttribute(viewToggler.selectors.attrToggle));
                if (targetEl.classList.contains(viewToggler.selectors.shown)) {
                    // first checking nominatively declared visibility...
                    viewToggler.hide(targetEl, toggleTrigger);
                } else if (targetEl.classList.contains(viewToggler.selectors.hidden)) {
                    viewToggler.show(targetEl, toggleTrigger);
                } else {
                    // ..then trying to investigate
                    const {height, width} = targetEl.getBoundingClientRect();
                    if (height && width) {
                        viewToggler.hide(targetEl, toggleTrigger);
                    } else if (!height || !width) {
                        viewToggler.show(targetEl, toggleTrigger);
                    }
                }
            }
        },

        setFocusIn(element) {
            const focusable = element.querySelectorAll(
                'button, [href]:not(.sr-only-focusable), input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            if (focusable[0]) {
                focusable[0].focus();
            }
        },

        triggerListener(e) {
            if (e.detail.targets.length) {
                const action = e.type === viewToggler.evs.triggerHide ? 'hide' : 'show';
                e.detail.targets.map(pair => {
                    viewToggler[action](pair.target, pair.trigger, false);
                });
            }
        },

        init() {
            document.body.addEventListener('click', viewToggler.control);
            document.body.addEventListener('keydown', e => {
                const code = e.keyCode || e.which;
                if (code === 13) {
                    // enter
                    viewToggler.control(e);
                }
                if (code === 27) {
                    // escape
                    viewToggler.hideAll(); // maybe hide only the one that is focuses? hmm...
                    // hiding all we don't know to which element the focus is to be reinstated
                }
            });
            document.addEventListener(viewToggler.evs.triggerHide, viewToggler.triggerListener);
            document.addEventListener(viewToggler.evs.triggerShow, viewToggler.triggerListener);
            // document.body.addEventListener('focus', viewToggler.control, true)
            // stuff has to be displayed/hidden on focus/focusout only if it's to be activated with the focus/hover in the first place
        },
    };

    UX.viewToggler = viewToggler;
})();
