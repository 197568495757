(() => {
    function footerAccordions() {
        function beforeFooterListShow(e) {
            if (e.detail.element.classList.contains('footer__accordion')) {
                $(e.detail.element).slideDown('fast');
            }
        }

        function beforeFooterListHide(e) {
            if (e.detail.element.classList.contains('footer__accordion')) {
                $(e.detail.element).slideUp('fast');
            }
        }

        $(document)
            .on('screen-md-on', e => {
                document.addEventListener('viewToggler.beforeShow', beforeFooterListShow);
                document.addEventListener('viewToggler.beforeHide', beforeFooterListHide);
            })
            .on('screen-md-off', e => {
                document.removeEventListener('viewToggler.beforeShow', beforeFooterListShow);
                document.removeEventListener('viewToggler.beforeHide', beforeFooterListHide);
            });
    }

    footerAccordions(); // @TODO -> initiate properly in last js (or not?) ;-)
})();
